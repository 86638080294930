/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Towel = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M63 67.5H27a4.502 4.502 0 00-4.5 4.5c0 2.484 2.016 4.5 4.5 4.5h36c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H24c-5.799 0-10.5 4.698-10.5 10.5S18.201 82.5 24 82.5h48c5.802 0 10.5-4.698 10.5-10.5S77.802 61.5 72 61.5h-9" />
      <path d="M82.5 72l-7.437-48.324A11.993 11.993 0 0063.204 13.5H32.796a11.995 11.995 0 00-11.859 10.176L13.5 72M37.548 19.452l.045 7.641" />
      <path d="M44.97 25.53l-7.377 1.563-4.47 7.284" />
      <path d="M42.159 34.329l-4.566-7.236-7.47-1.563M61.578 42.996l.03 5.139M66.567 47.085l-4.959 1.05-3.006 4.899" />
      <path d="M64.68 53.004l-3.072-4.869-5.022-1.05M61.563 18.954l.03 5.139M66.555 23.043l-4.962 1.05-3.006 4.899" />
      <path d="M64.665 28.962l-3.072-4.869-5.025-1.05" />
    </g>
  </svg>
);

Towel.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Towel.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Towel;
