/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const HandBag = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M67.5 31.5c0-24-39-24-39 0M28.62 37.548h39a6 6 0 006-6l5.895 33.393c1.614 9.177-5.439 17.607-14.775 17.607H31.5c-9.351 0-16.383-8.451-14.775-17.607l5.895-33.393c0 3.318 2.688 6 6 6zM31.5 43.5v6M28.5 43.5H69" />
    </g>
  </svg>
);

HandBag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

HandBag.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default HandBag;
