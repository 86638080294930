/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Suit = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M48.5 44.003c-5.814-8.472-12-19.5-12-19.5l3.189-3h17.499l3.264 3s-6.456 9.885-11.952 19.5z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        d="M54.5 56.003a1.5 1.5 0 10.001 3.001 1.5 1.5 0 00-.001-3.001M54.5 62.003a1.5 1.5 0 10.001 3.001 1.5 1.5 0 00-.001-3.001M42.5 56.003a1.5 1.5 0 10.001 3.001 1.5 1.5 0 00-.001-3.001M42.5 62.003a1.5 1.5 0 10.001 3.001 1.5 1.5 0 00-.001-3.001"
        fill={color}
      />
      <path
        d="M48.5 63.503v-19.5M36.596 24.503c-3.663 0-13.596 4.5-13.596 4.5l-7.5 34.5h9l7.002-22.374a1.754 1.754 0 013.498.192l-4.5 34.182h13.686c.036-.09 4.314-12 4.314-12l4.563 12H66.5l-4.341-34.344a1.592 1.592 0 013.174-.195L72.5 63.506h9L74 29.003s-10.605-4.5-13.734-4.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </g>
  </svg>
);

Suit.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Suit.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Suit;
