/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Pet = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M30.202 44.5h21.999c4.845 0 9.213-2.913 11.076-7.386L67.702 26.5l3 7.323c7.209 4.548 9 4.578 9 9.738a3.934 3.934 0 01-3.936 3.936h-3.564l-4.5 9-3.933 15.726a3.004 3.004 0 01-2.913 2.274 2.264 2.264 0 01-2.259-2.274c.072-10.116-.678-12.726-13.398-12.726-16.989 0-18.561 5.727-23.226 13.536-1.632 2.733-5.313 1.266-4.338-1.149 1.938-4.791 6.096-15.171 8.766-22.617C21.856 47.026 19.702 43 19.702 37c0-14.484 15.609-20.334 17.493-9.054.48 2.898-3.591 4.257-4.404 1.815-2.493-7.455-12.822 8.598-2.589 14.739z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        d="M40.702 59.863l1.197 12.756c.18 1.923-2.484 2.631-3.282.87L33.001 61.15M65.248 66.316l4.563 6.846c1.71 2.565 5.1 1.077 4.122-1.152-.621-1.416-1.485-3.645-6.231-15.513"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        d="M71.5 40a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0071.5 40"
        fill={color}
      />
    </g>
  </svg>
);

Pet.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Pet.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Pet;
