import PropTypes from 'prop-types';
import React from 'react';

const sizes = {
  default: 'py-2 px-8',
  lg: 'py-4 px-16',
  xl: 'py-8 px-16 text-lg',
};

const Button = ({
  children,
  className,
  disabled,
  size,
}) => (
  <button
    className={`
      ${sizes[size] || sizes.default}
      ${className}
      bg-white
      font-bold
      hover:bg-primary-darker
      hover:text-white
      rounded
      text-primary
    `}
    disabled={disabled}
    type="button"
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string.isRequired,
};

Button.defaultProps = {
  className: '',
  disabled: false,
};

export default Button;
