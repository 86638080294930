/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Phone = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path
        d="M67.5 76.5c0 3.312-2.688 6-6 6h-27c-3.312 0-6-2.688-6-6v-57c0-3.312 2.688-6 6-6h27c3.312 0 6 2.688 6 6v57zM67.5 70.5h-39M67.5 25.5h-39M40.5 19.5h15"
        strokeLinecap="round"
      />
      <path d="M48 78a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0148 78z" />
    </g>
  </svg>
);

Phone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Phone.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Phone;
