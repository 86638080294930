import PropTypes from 'prop-types';
import React from 'react';

const Card = ({
  className, title, explain,
}) => (
  <div
    className={className}
    style={{
      boxShadow: '0 10px 28px rgba(0, 0, 0, 0.09)',
    }}
  >
    <p className="
      bg-primary
      border
      border-gray-200
      border-solid
      font-semibold
      lg:text-xl
      p-2
      rounded-t-lg
      text-lg
      text-white
    "
    >
      {title}
    </p>
    <p className="lg:text-l mt-2 p-4 rounded-b-lg text-base">
      {explain}
    </p>
  </div>
);

Card.propTypes = {
  className: PropTypes.string,
  explain: PropTypes.string,
  title: PropTypes.string,
};

Card.defaultProps = {
  className: '',
  explain: '',
  title: '',
};

export default Card;
