import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import appStore from '../assets/images/appStore.png';
import config from '../../site-config';
import playStore from '../assets/images/playStore.png';

const Download = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section
      className=" py-8 lg:py-16 bg-primary text-center"
      id={id}
    >
      <div className="container mx-auto text-center">
        <h2 className="text-white">
          {t('download')}
        </h2>
        <p className="subtitle mt-4 mx-2 text-white">
          {t('downloadIt')}
        </p>
        <div
          className="
            flex
            flex-col
            items-center
            justify-center
            mt-16
            sm:flex-row
          "
        >
          {config.playStoreLink && (
            <div className="flex flex-col relative">
              <a
                className="p-4"
                href={config.playStoreLink}
              >
                <img
                  alt="play store"
                  src={playStore}
                />
              </a>
            </div>
          )}
          {config.appStoreLink && (
            <div className="flex flex-col relative">
              <a
                className="p-4"
                href={config.appStoreLink !== 'soon' && config.appStoreLink}
              >
                <img
                  alt="app store"
                  src={appStore}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Download.propTypes = {
  id: PropTypes.string,
};

Download.defaultProps = {
  id: 'download',
};

export default Download;
