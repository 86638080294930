/* eslint-disable sort-keys */
export default [
  {
    question: 'faqQnA.where',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.whereExplain',
      },
    ],
  },
  {
    question: 'faqQnA.how',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.howExplain',
      },
    ],
  },
  {
    question: 'faqQnA.ownTag',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.ownTagExplain',
      },
    ],
  },
  {
    question: 'faqQnA.found',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.foundExplain',
      },
    ],
  },
  {
    question: 'faqQnA.lose',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.loseExplain',
      },
    ],
  },
  {
    question: 'faqQnA.contactWithMe',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.contactWithMeExplain',
      },
      {
        type: 'bullets',
        content: [
          'faqQnA.contactWithMeBullet1',
          'faqQnA.contactWithMeBullet2',
          'faqQnA.contactWithMeBullet3',
          'faqQnA.contactWithMeBullet4',
        ],
      },
      {
        type: 'text',
        content: 'faqQnA.contactWithMeExplain2',
      },
    ],
  },
  {
    question: 'faqQnA.tagPets',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.tagPetsExplain',
      },
      {
        type: 'text',
        className: 'font-bold',
        content: 'faqQnA.tagPetsRemark',
      },
    ],
  },
  {
    question: 'faqQnA.uninstall',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.uninstallExplain',
      },
    ],
  },
  {
    question: 'faqQnA.moreThanOne',
    answer: [
      {
        type: 'text',
        content: 'faqQnA.moreThanOneExplain',
      },
    ],
  },
];
