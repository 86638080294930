/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Keys = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M51.5 39c0-9.114-7.389-16.5-16.5-16.5S18.5 29.886 18.5 39c0 7.551 5.079 13.902 12 15.861V58.5l-3 2.997 3 3.003v3l-3 3 3 3V78l4.5 4.5 4.5-4.5V54.861c6.921-1.959 12-8.31 12-15.861z" />
      <path d="M35 28.5c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5a4.502 4.502 0 01-4.5-4.5c0-2.484 2.016-4.5 4.5-4.5zM39.5 57.039c5.016 2.895 11.226 2.919 16.275.099L59 60v4.5h4.5l1.5 1.572V70.5h3.951l3.186 3H78.5v-6.363L62.138 50.775c3.51-6.279 2.607-14.364-2.73-19.701-3.762-3.762-8.886-5.328-13.785-4.698" />
      <path d="M35 33V19.5c0-3.312 2.688-6 6-6s6 2.688 6 6v6.756" />
    </g>
  </svg>
);

Keys.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Keys.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Keys;
