/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Wallet = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M63 52.5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0163 52.5zM25.5 34.5h3M34.5 34.5h3M43.5 34.5h3M52.5 34.5h3M61.5 34.5h3M25.5 73.5h3M34.5 73.5h3M43.5 73.5h3M52.5 73.5h3M61.5 73.5h3" />
      <path d="M82.494 55.5c0 3.3-2.697 6-6 6h-15c-3.3 0-6-2.7-6-6v-3c0-3.3 2.7-6 6-6h15c3.303 0 6 2.7 6 6v3zM73.5 46.5v-9c0-4.95-4.053-9-9-9h-42M58.5 22.5h-36c-3.969 0-3.963 6 0 6" />
      <path d="M73.5 61.5v9c0 4.95-4.053 9-9 9h-42c-4.95 0-9-4.05-9-9v-45c0-4.95 4.05-9 9-9h36c4.947 0 9 2.7 9 6v6" />
    </g>
  </svg>
);

Wallet.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Wallet.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Wallet;
