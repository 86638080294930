/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Glasses = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <path
      d="M96.5 46.401h-9.441v-19.2h-9.6V19.2H64.658v27.201H32.657V19.2H19.856v8.001h-9.6v19.2H.497v3.201h9.759v19.2h9.6v8.001h12.801V49.602h32.001v27.201h12.801v-8.001h9.6v-19.2H96.5v-3.201zm-83.04 19.2V30.402h6.399v35.199H13.46zm15.999 7.998H23.06V22.398h6.399v51.201zm44.802 0h-6.399V22.398h6.399v51.201zm9.6-7.998h-6.399V30.402h6.399v35.199z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

Glasses.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Glasses.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Glasses;
