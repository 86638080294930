/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Boots = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M82.5 24.003v49.5c0 3.312-2.688 6-6 6h-51c-6.627 0-12-5.373-12-12s5.373-12 12-12h8.238c4.974 0 9.435-3.069 11.208-7.719l9.804-30.531 27.75 6.75z" />
      <path d="M48.837 35.436l16.203 3.117a4.504 4.504 0 005.271-3.567 4.502 4.502 0 00-3.57-5.268l-15.063-2.901M42.114 52.2l16.563 4.668a4.494 4.494 0 005.472-3.243 4.493 4.493 0 00-3.24-5.472L46.05 44.346M15 73.503h67.5" />
    </g>
  </svg>
);

Boots.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Boots.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Boots;
