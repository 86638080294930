/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Glasses = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path
        d="M35.766 45.138l28.812-16.53a4.499 4.499 0 016.144 1.647"
        strokeLinecap="round"
      />
      <path
        d="M60.249 45.138l-28.806-16.53a4.499 4.499 0 00-6.144 1.647"
        strokeLinecap="round"
      />
      <path d="M31.509 44.358c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zM64.509 44.358c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zM43.509 56.358c0-2.484 2.016-4.5 4.5-4.5s4.5 2.016 4.5 4.5" />
      <path
        strokeLinecap="round"
        d="M19.506 57h-3M76.506 57h3"
      />
    </g>
  </svg>
);

Glasses.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Glasses.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default Glasses;
