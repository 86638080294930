/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const TravelBag = ({ color, size }) => (
  <svg
    height={size}
    viewBox="0 0 96 96"
    width={size}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <path d="M74 25c0-4.404-3.6-8-8-8H30c-4.4 0-8 3.596-8 8v48c0 4.4 3.6 8 8 8h36c4.4 0 8-3.6 8-8V25zM30 17v-5.206h11.145V17M54 17v-5.206h11.145V17M34 81v4M62 81v4" />
      <path d="M62 65c0 2.212-1.796 4-4 4H38c-2.212 0-4-1.788-4-4V53h28v12zM34 34h28M38 34v4" />
    </g>
  </svg>
);

TravelBag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

TravelBag.defaultProps = {
  color: '#269AFF',
  size: 96,
};

export default TravelBag;
