import { FaChevronDown as ArrowDown } from 'react-icons/fa';
import { graphql, Link } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Collapsible from 'react-collapsible';
import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Button from '../components/Button';
import Card from '../components/Card';
import Download from '../components/Dowload';
import faqQnA from '../data/faq';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import whereCanIUseIt from '../data/where-can-i-use-it';

import '../theme/video.css';

const IndexPage = ({ data }) => {
  const {
    chat,
    findTag,
    hero,
    mface,
    rface,
    tag,
    vface,
  } = data;
  const { t } = useTranslation();

  return (
    <Layout
      headerContent={(
        <>
          <AnchorLink
            className="px-4"
            href="#howto"
          >
            {t('howTo')}
          </AnchorLink>
          <AnchorLink
            className="px-4"
            href="#faq"
          >
            {t('faq')}
          </AnchorLink>
          <AnchorLink
            className="px-4"
            href="#about-us"
          >
            {t('aboutusTitle')}
          </AnchorLink>
          <Link
            className="px-4"
            to="/blog"
          >
            {t('blog')}
          </Link>
        </>
      )}
    >
      <SEO title={t('title')} />
      <section className="pt-16 bg-primary">
        <div className="container mx-auto px-8 lg:flex ">
          <div className="text-center py-8 lg:text-left lg:w-1/2 sm:py-16">
            <h1 className="text-white">
              {t('title')}
            </h1>
            <p className="text-xl lg:text-2xl mt-4 font-light text-white">
              {t('subtitle')}
            </p>
            <div className="flex flex-col sm:flex-row mt-8 md:mt-16 px-8">
              <div className="mx-auto">
                <AnchorLink href="#howto">
                  <Button className="min-w-180 mb-4 sm:w-auto" size="lg">
                    {t('getStarted')}
                  </Button>
                </AnchorLink>
              </div>
              <div className="mx-auto">
                <AnchorLink href="#download">
                  <Button className="min-w-180 sm:w-auto" size="lg">
                    {t('download')}
                  </Button>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 justify-center">
            <Image fluid={hero.childImageSharp.fluid} />
          </div>
        </div>
        <div
          className="
            bg-secondary
            flex
            flex-col
            items-center
            justify-center
            p-4
            sm:flex-row
          "
        >
          <p className="mx-4 mb-4 sm:mb-0 text-center text-white">
            {t('didYouFound')}
          </p>
          <div className="mx-auto sm:mx-0">
            <Link to="/found">
              <Button className="min-w-180 sm:w-auto" size="lg">
                {t('here')}
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section
        className="py-16 lg:py-24"
        id="howto"
      >
        <div className="container mx-auto text-center">
          <h2>
            {t('howTo')}
          </h2>
          <div className="video flex flex-col sm:flex-row mt-12">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/rj1Z7rx-b0Y?controls=0&rel=0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
          </div>
          <div className="flex flex-col sm:flex-row mt-12">
            <div className="flex-1 mx-4">
              <div className="justify-center pb-4">
                <Image fluid={tag.childImageSharp.fluid} />
              </div>
              <Card
                className="mb-8"
                explain={t('tagExplain')}
                title={t('tag')}
              />
            </div>
            <div className="flex-1 mx-4">
              <div className="justify-center pb-4">
                <Image fluid={findTag.childImageSharp.fluid} />
              </div>
              <Card
                className="mb-8"
                explain={t('findExplain')}
                title={t('find')}
              />
            </div>
            <div className="flex-1 mx-4">
              <div className="justify-center pb-4">
                <Image fluid={chat.childImageSharp.fluid} />
              </div>
              <Card
                className="mb-8"
                explain={t('contactExplain')}
                title={t('contact')}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto py-16 lg:py-24">
        <div className="text-center">
          <h2>
            {t('whereCanIUseIt')}
          </h2>
        </div>
        <div
          className="
            gap-4
            grid
            grid-cols-3
            grid-flow-col
            grid-rows-4
            lg:grid-cols-4
            lg:grid-rows-3
            pt-8
          "
        >
          {whereCanIUseIt.map(({ icon: Icon, translation }) => (
            <div className="flex flex-col items-center py-4" key={translation}>
              <Icon />
              <p className="text-l lg:text-xl mt-2">
                {t(translation)}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-8 text-center text-xl text-primary">
          {t('other')}
        </div>
      </section>
      <section
        className="container mx-auto py-16 lg:py-24"
        id="faq"
      >
        <div className="text-center">
          <h2>
            {t('faq')}
          </h2>
        </div>
        {faqQnA.map(({ answer, question }, index) => (
          <Collapsible
            className="cursor-pointer shadow-md rounded-md my-8 mx-4"
            contentInnerClassName="cursor-auto p-4"
            easing="ease-in-out"
            key={question}
            openedClassName="cursor-pointer rounded-md shadow-md my-8 mx-4"
            transitionTime={400}
            trigger={(
              <div className="flex flex-row items-center">
                <div className="flex-1">
                  {`${index + 1}. ${t(question)}`}
                </div>
                <ArrowDown />
              </div>
            )}
            triggerClassName="bg-primary rounded-md p-4 text-white"
            triggerOpenedClassName="bg-primary rounded-md p-4 text-white"
            triggerTagName="div"
          >
            {answer.map(({ className, content, type }) => (
              <React.Fragment key={content}>
                {type === 'text' && (
                  <p className={`text-base lg:text-l ${className || ''}`}>
                    {t(content)}
                  </p>
                )}
                {type === 'bullets' && (
                  <ul className="list-disc mx-16">
                    {content.map((bullet) => (
                      <li key={bullet}>
                        {t(bullet)}
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </Collapsible>
        ))}
      </section>
      <Download />
      <section
        className="container mx-auto py-16 lg:py-24"
        id="about-us"
      >
        <div className="text-center mb-4">
          <h2>
            {t('aboutusTitle')}
          </h2>
        </div>
        <div
          className="
            container
            flex
            flex-col
            items-center
            lg:flex-row
            mx-auto
            px-4
          "
        >
          <div
            className="
              flex
              lg:flex-col
              lg:w-1/5
              mb-auto
              md:flex-row
              md:w-4/5
              sm:w-4/5
              w-4/5
              xl:w-1/5
            "
          >
            <div
              className="
                lg:w-4/5
                md:p-4
                ml-auto
                p-1
                sm:px-1
                sm:w-full
                w-full
                xl:w-3/5
              "
            >
              <a
                href="https://linkedin.com/in/victor-a-garcia-alvarez-89a42248"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image fluid={vface.childImageSharp.fluid} />
              </a>
            </div>
            <div
              className="
                lg:w-4/5
                md:p-4
                ml-auto
                p-1
                sm:px-1
                sm:w-full
                w-full
                xl:w-3/5
              "
            >
              <a
                href="https://linkedin.com/in/rubenvot"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image fluid={rface.childImageSharp.fluid} />
              </a>
            </div>
            <div
              className="
                lg:w-4/5
                md:p-4
                ml-auto
                p-1
                sm:px-1
                sm:w-full
                w-full
                xl:w-3/5
              "
            >
              <a
                href="https://linkedin.com/in/mschez"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image fluid={mface.childImageSharp.fluid} />
              </a>
            </div>
          </div>
          <div
            className="
              lg:px-16
              md:px-3
              sm:px-3
              w-4/5
              xl:px-16
            "
          >
            <>
              <div className="mb-8 lg:pr-24">
                <p className="mt-2 text-base font-light text-justify">
                  <Trans i18nKey="aboutusContent" />
                </p>
              </div>
            </>
          </div>
        </div>
        <div className="container mx-auto my-8 text-center">
          <div className="mx-auto">
            <Link to="/acknowledgment">
              {t('acknowledgment')}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    chat: file(relativePath: { eq: "chat.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    findTag: file(relativePath: { eq: "findTag.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stores: file(relativePath: { eq: "stores.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tag: file(relativePath: { eq: "tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mface: file(relativePath: { eq: "m_face.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rface: file(relativePath: { eq: "r_face.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vface: file(relativePath: { eq: "v_face.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
