import Boots from '../assets/icons/Boots';
import Drone from '../assets/icons/Drone';
import Glasses from '../assets/icons/Glasses';
import Gym from '../assets/icons/Gym';
import HandBag from '../assets/icons/HandBag';
import Keys from '../assets/icons/Keys';
import Pet from '../assets/icons/Pet';
import Phone from '../assets/icons/Phone';
import Suit from '../assets/icons/Suit';
import Towel from '../assets/icons/Towel';
import TravelBag from '../assets/icons/TravelBag';
import Wallet from '../assets/icons/Wallet';

export default [
  {
    icon: TravelBag,
    translation: 'backpacks',
  },
  {
    icon: Wallet,
    translation: 'wallets',
  },
  {
    icon: Boots,
    translation: 'sneakers',
  },
  {
    icon: HandBag,
    translation: 'handbags',
  },
  {
    icon: Keys,
    translation: 'keychains',
  },
  {
    icon: Towel,
    translation: 'towels',
  },
  {
    icon: Pet,
    translation: 'pets',
  },
  {
    icon: Glasses,
    translation: 'glasses',
  },
  {
    icon: Drone,
    translation: 'drones',
  },
  {
    icon: Phone,
    translation: 'mobiles',
  },
  {
    icon: Suit,
    translation: 'jackets',
  },
  {
    icon: Gym,
    translation: 'gymStuff',
  },
];
